var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"tdf2VpjUzKkOy2OsE7LIw"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import {
  CaptureConsole as CaptureConsoleIntegration,
  // Offline as OfflineIntegration
} from "@sentry/integrations";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENV || 'development';

Sentry.init({
  environment: ENVIRONMENT,
  dsn: SENTRY_DSN || 'https://2f9e9fb48475489ba21ea5a98046f604@sentry.sw.hiveon.net/25',
  tracesSampleRate: 0.25,
  integrations: [
    new CaptureConsoleIntegration(
    {
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error'],
    }),
    // new OfflineIntegration(),
  ],
});
